import ky from "ky-universal";

const API_HOSTNAME = process.env.API_HOSTNAME;
const STREAM_HOSTNAME = process.env.STREAM_HOSTNAME;
const HOSTNAME = process.env.HOSTNAME;

const _getFullUrl = (url) => {
  return `${API_HOSTNAME}/${url}`;
};

const _getStreamUrl = (url) => {
  return `${STREAM_HOSTNAME}/${url}`;
};

const _getHeaderAndJSONPayloadFromResponse = async (response) => {
  const { headers, status } = response;
  const json = await response.json();
  return { headers, json, status };
};

const _errorHandle = async (error) => {
  if (error.message === "Request Timed Out") {
    return;
  }
  if (error.response) {
    const payload = await _getHeaderAndJSONPayloadFromResponse(error.response);
    const newError = new Error("Network error");
    newError.response = payload;
    throw newError;
  }
  throw error;
};

const _get = async (url, payload) => {
  try {
    const fullUrl = _getFullUrl(url);
    const response = await ky.get(fullUrl, {
      ...payload,
    });
    return await _getHeaderAndJSONPayloadFromResponse(response);
  } catch (error) {
    await _errorHandle(error);
  }
};

const _post = async (url, payload) => {
  try {
    const fullUrl = _getFullUrl(url);
    const response = await ky.post(fullUrl, {
      ...payload,
    });
    return await _getHeaderAndJSONPayloadFromResponse(response);
  } catch (error) {
    await _errorHandle(error);
  }
};

const _put = async (url, payload) => {
  try {
    const fullUrl = _getFullUrl(url);
    const response = await ky.put(fullUrl, { ...payload });
    return await _getHeaderAndJSONPayloadFromResponse(response);
  } catch (error) {
    await _errorHandle(error);
  }
};

const _delete = async (url, payload) => {
  try {
    const fullUrl = _getFullUrl(url);
    const response = await ky.delete(fullUrl, { ...payload });
    return await _getHeaderAndJSONPayloadFromResponse(response);
  } catch (error) {
    await _errorHandle(error);
  }
};

export {
  _get,
  _post,
  _put,
  _delete,
  HOSTNAME,
  API_HOSTNAME,
  _getFullUrl,
  _getStreamUrl,
};
