const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const RELEASE =
  process.env.VERCEL_GIT_COMMIT_SHA ||
  process.env.NEXT_VERCEL_GIT_COMMIT_SHA ||
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ||
  "dev";

const sentryConfig = {
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.0001,
  sampleRate: 0.25,
  release: RELEASE,
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications",
    "NotSupportedError: Failed to load because no supported source was found",
    "TimeoutError",
    "NetworkError",
    "TypeError",
    "SyntaxError",
    "ValidationError",
    "ReferenceError",
    "Error: NotAllowedError",
    "NotSupportedError",
    "UnhandledRejection",
    "NotAllowedError",
  ],
};

export { sentryConfig };
