import React from "react";
import { I18nProvider } from "@lingui/react";

import { RootProviders } from "../providers/root";
import { i18n } from "@lingui/core";
import "../components/turntable.css";
import { RequestProvider } from "../contexts/request";
import { useLinguiInit } from "../i18n";
/**
 * Selectively render ChakraProvider.
 * On custom css overlay pages, we don't need ChakraProvider
 */
const App = ({ Component, pageProps }) => {
  const { customOverlay, translation } = pageProps;
  useLinguiInit(translation);

  if (customOverlay) {
    return (
      <RequestProvider>
        <Component {...pageProps} />
      </RequestProvider>
    );
  }

  return (
    <I18nProvider i18n={i18n}>
      <RootProviders>
        <Component {...pageProps} />
      </RootProviders>
    </I18nProvider>
  );
};

export default App;
