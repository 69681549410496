import { extendTheme } from "@chakra-ui/react";
const _theme = extendTheme({
  styles: {
    global: {
      body: {
        background: "rgba(0, 0, 0, 0)",
      },
    },
  },
  fonts: {
    heading: "Comfortaa, sans-serif",
    body: "IBM Plex Mono, monospace",
  },
  borders: {
    default: "1px solid black",
  },
  colors: {
    transparent: "transparent",
    blackish: "#333",
    whitish: "#f2f7f5",
    "saweria-purple": {
      light: "#c2ace6",
      normal: "#a683dd",
      dark: "#7e67a1",
    },
    "saweria-blue": {
      normal: "#108ee9",
    },
    white: "#fff",
    "saweria-pink": {
      light: "#ffbdc4",
      normal: "#fe98a3",
      dark: "#ba797f",
    },
    "saweria-yellow": {
      light: "#fac76c",
      normal: "#faae2b",
      dark: "#b68228",
    },
    "saweria-green": {
      light: "#b2e0e6",
      normal: "#8bd3dd",
      dark: "#6e9ba1",
    },
  },
  shadows: {
    buttonRaised: "0.4rem 0.4rem 0 #222",
    buttonPressed: "0.1rem 0.1rem 0 #222",
    raised: "0.6rem 0.6rem 0 #222",
    pressed: "0.2rem 0.2rem 0 #222",
  },
});

export default _theme;
