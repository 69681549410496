import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "../theme";
import { RequestProvider } from "../contexts/request";

/**
 * Wraps all provider needed at root level
 * @param {Object} ReactProps
 */
const RootProviders = ({ children, request }) => {
  return (
    <ChakraProvider theme={theme}>
      <RequestProvider {...request}>{children}</RequestProvider>
    </ChakraProvider>
  );
};
export { RootProviders };
