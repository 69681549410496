import React from "react";
import useLocalStorage from "react-use/lib/useLocalStorage";

import { useRouter } from "next/dist/client/router";
import { _put, _post, _get, HOSTNAME, _delete } from "../utils/request";
import { queryCache } from "react-query";

const RequestContext = React.createContext({});

const RequestProvider = ({ children, initialValue }) => {
  const router = useRouter();

  const userInfoKey = "saweria-user-info";

  const [userInfo, _setUserInfo] = useLocalStorage(userInfoKey, {});

  const setUserInfo = (data) => {
    _setUserInfo(data);
  };

  const register = async (email, password, username) => {
    try {
      await post("auth/register", {
        json: { email, password, username },
      });
      router.push("/login");
    } catch (error) {
      throw error;
    }
  };

  const login = async ({ email, password, otp = "" }) => {
    let payload = { email, password };
    if (otp.length) {
      payload = { ...payload, otp };
    }

    try {
      const { json, headers } = await post("auth/login", {
        json: { ...payload },
      });
      return { json, headers };
    } catch (error) {
      throw error;
    }
  };

  const clearUserInfo = async () => {
    _setUserInfo({});
    await queryCache.invalidateQueries();
    router.push("/login");
  };

  const logout = async () => {
    try {
      await _post("auth/logout", { headers: _getHeaders({}) });
    } catch (error) {
    } finally {
      await clearUserInfo();
    }
  };

  const _errorHandle = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        clearUserInfo();
      }
    }
  };

  const _getHeaders = (headers) => {
    const { token } = userInfo;
    if (token) {
      return { Authorization: token, ...headers };
    } else {
      return headers;
    }
  };

  const get = async (
    url,
    payload,
    headers = {},
    handleError = _errorHandle
  ) => {
    try {
      return await _get(url, { ...payload, headers: _getHeaders(headers) });
    } catch (e) {
      handleError(e);
      throw e;
    }
  };

  const post = async (url, payload, headers = {}) => {
    try {
      return await _post(url, { ...payload, headers: _getHeaders(headers) });
    } catch (e) {
      _errorHandle(e);
      throw e;
    }
  };

  const put = async (url, payload, headers = {}) => {
    try {
      return await _put(url, { ...payload, headers: _getHeaders(headers) });
    } catch (e) {
      _errorHandle(e);
      throw e;
    }
  };

  const del = async (url, payload, headers = {}) => {
    try {
      return await _delete(url, { ...payload, headers: _getHeaders(headers) });
    } catch (e) {
      _errorHandle(e);
      throw e;
    }
  };

  return (
    <RequestContext.Provider
      value={{
        get,
        post,
        put,
        del,
        logout,
        login,
        register,
        setUserInfo,
        userInfo,
        clearUserInfo,
        HOSTNAME,
        _getHeaders,
        ...initialValue,
      }}
    >
      {children}
    </RequestContext.Provider>
  );
};

const useRequest = () => {
  const state = React.useContext(RequestContext);
  return state;
};

export { RequestProvider, useRequest };
