import { i18n } from "@lingui/core";
import { useRouter } from "next/router";
import { useEffect } from "react";
import en from "./locales/en";
import id from "./locales/id";
import { FormErrorMessage as FEM } from "@chakra-ui/react";
import { useLingui } from "@lingui/react";

const i18nEN = i18n.loadAndActivate({ locale: "en", messages: en });
const i18nID = i18n.loadAndActivate({ locale: "id", messages: id });

/**
 * Load messages for requested locale and activate it.
 * This function isn't part of the LinguiJS library because there are
 * many ways how to load messages — from REST API, from file, from cache, etc.
 */
export async function loadCatalog(locale) {
  const catalog = await import(`@lingui/loader!./locales/${locale}.po`);
  return catalog.messages;
}

export async function loadAndActivate(locale, i18n) {
  const messages = await loadCatalog(locale);
  i18n.loadAndActivate({ locale, messages });
}

export function useLinguiInit(messages) {
  const router = useRouter();
  const locale = router.locale || router.defaultLocale;
  const isClient = typeof window !== "undefined";

  if (!isClient && locale !== i18n.locale) {
    if (locale === "id") {
      return i18nID;
    }
    return i18nEN;
  }
  if (isClient && !i18n.locale) {
    // first client render
    i18n.loadAndActivate({ locale, messages });
  }

  useEffect(() => {
    const localeDidChange = locale !== i18n.locale;
    if (localeDidChange) {
      i18n.loadAndActivate({ locale, messages });
    }
  }, [locale]);

  useEffect(() => {
    i18n.loadAndActivate({ locale, messages });
  }, [locale, messages]);

  return i18n;
}

export function getFormError(code, _) {
  if (!code) {
    return "";
  }
  return _(code);
}

export const FormErrorMessage = ({ children }) => {
  const { _ } = useLingui();

  return <FEM>{getFormError(children, _)}</FEM>;
};
